:root {
  --theme-bg-color: #fff;
  --theme-text-color: #000;
  --theme-color: #096fec;
  --primary-text-color: #000;
  --secondary-text-color: #000;
  --third-text-color: #000;
  --header-color: #000;
}

$black: #000;
$white: #fff;

$default: #bfbfbf;
$good: #3da930;
$moderate: #ffb000;
$low: #ff8500;
$bad: #d91b1b;

// Font Family
$primary-font: ATTAleckSans_Rg;
$secondary-font: ATTAleckSans_Bd;
$third-font: ATTAleckSans_Md;
