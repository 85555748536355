.dashboard-spinner {
  width: 100%;
  text-align: center;

  .dashboard-spinner-wrapper {
    margin: 10em 0;

    .text {
      font-size: 16px;
    }

    .reload-svg {
      animation: spin 4s linear infinite;
      -webkit-animation: spin 4s linear infinite;

      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }

        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}