.att-login {
  margin-bottom: 6px;
  font-family: $primary-font;

  .btn {
    font-family: $secondary-font;
    font-size: 16px;
    line-height: 48px;
    background-color: #0057b8;
    color: $white;
    border-radius: 9999px;
    width: 207px;
    height: 48px;
    margin-bottom: 24px;
    padding: 0;
    text-align: center;
  }

  .call-text {
    font-size: 16px;
  }

  .bg-img {
    height: 374px;
    overflow: hidden;
    transform: scale(1);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .col-lg-6 {
    padding: 0;
  }

  .heading {
    font-size: 16px;
    font-family: $secondary-font;
    margin-bottom: 24px;
  }

  .text1 {
    font-size: 32px;
    font-family: $third-font;
    margin-bottom: 24px;
  }

  .text2 {
    font-size: 20px;
    margin-bottom: 56px;
  }

  .section-1 {
    .bg-img {
      height: 374px;
    }

    .text-holder {
      color: $white;
      background-color: #1d2329;
      padding: 54px 15px 56px 35px;

      .text1 {
        line-height: 120%;
      }
    }
  }

  .section-2 {
    padding: 56px 27px 56px 23px;

    .text1 {
      text-align: center;
      margin: 0 auto 20px;
    }

    .quality-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 50px;

      .holder {
        width: 100px;
        margin: 0 20px;
        padding-top: 20px;

        .image-wrapper {
          height: 65px;

          img {
            height: 54px;
          }
        }

        .text {
          font-size: 20px;
          width: 100px;
        }
      }
    }
  }

  .section-3 {
    background-color: #e7e7e8;

    .text-holder {
      padding: 40px 40px 56px 35px;

      .heading {
        color: #0057b8;
      }
    }
  }

  .section-4 {
    .img-wrapper {
      margin: 56px auto 0;
      padding: 0 35px;
      text-align: center;
    }

    .text-holder {
      padding: 0 40px 56px 35px;
      margin-top: 44px;

      .heading {
        color: #0057b8;
      }
    }
  }

  .section-5 {
    .bg-img {
      height: 343px;
    }

    .text-holder {
      color: $white;
      background-color: #1d2329;
      padding: 56px 28px 56px 35px;
    }
  }
}

@media (min-width: 992px) {
  .att-login {
    margin-bottom: 47px;

    .text1 {
      font-size: 40px;
    }

    .section-1 {
      .bg-img {
        height: 100%;
      }

      .text-holder {
        padding: 110px 96px 109px 150px;

        .text1 {
          font-size: 60px;
          line-height: 105%;
        }

        .text2 {
          font-size: 24px;
        }
      }
    }

    .section-2 {
      padding: 138px 200px 178px 200px;

      .text1 {
        margin: 0 auto 60px;
      }

      .quality-wrapper {
        .holder {
          margin: 0 30px;
        }
      }
    }

    .section-3 {
      .bg-img {
        height: 100%;
      }

      .img-wrapper {
        // text-align: right;
        margin-right: 0;
      }

      .text-holder {
        padding: 182px 137px 123px 98px;
      }
    }

    .section-4 {
      .img-wrapper {
        margin: auto;
      }

      .text-holder {
        padding: 323px 95px 300px 150px;
        margin-top: 0;
      }
    }

    .section-5 {
      .bg-img {
        height: 100%;
      }

      .text-holder {
        padding: 189px 167px 138px 140px;
      }
    }
  }
}
