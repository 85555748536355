.att-quick-tips-section {
  .no-data {
    h2 {
      color: red;
    }
  }

  h1 {
    text-transform: none;
  }
}

.att-quick-tips-container {
  position: relative;
  top: -100px;
  margin-bottom: -100px;
}