.no-data {
  text-align: center;

  img {
    width: 100%;
    max-width: 465px;
  }

  .text-holder {
    text-align: left;
    padding: 54px 15px 56px 35px;

    .heading {
      font-family: $third-font;
      color: #000000;
      font-size: 40px;
      line-height: 115%;
    }

    .text1 {
      font-size: 20px;
      line-height: 26px;
      align-items: center;
    }

    .call-text {
      font-size: 16px;
      line-height: 110%;
    }

    .btn {
      font-family: $secondary-font;
      font-size: 16px;
      line-height: 48px;
      background-color: #0057b8;
      color: $white;
      border-radius: 9999px;
      width: 207px;
      height: 48px;
      padding: 0;
      margin-bottom: 24px;
      text-align: center;
    }
  }

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .no-data {
    p {
      font-size: 40px;
      padding: 0;
    }
  }
}
