// base-url
@font-face {
  font-family: AttAleckSans;
  src: url('/static/media/fonts/ATTAleckSans_Rg.ttf') format('opentype');
}

@font-face {
  font-family: AttAleckSans;
  src: url('/static/media/fonts/ATTAleckSans_Bd.ttf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: ATTAleckSans_Bd;
  src: url('/static/media/fonts/ATTAleckSans_Bd.ttf') format('opentype');
}

@font-face {
  font-family: ATTAleckSans_Rg;
  src: url('/static/media/fonts/ATTAleckSans_Rg.ttf') format('opentype');
}

@font-face {
  font-family: ATTAleckSans_Md;
  src: url('/static/media/fonts/ATTAleckSans_Md.ttf') format('opentype');
}

@font-face {
  font-family: ATTAleckSans_Bd;
  src: url('/streaming-support/static/media/fonts/ATTAleckSans_Bd.ttf')
    format('opentype');
}

@font-face {
  font-family: ATTAleckSans_Rg;
  src: url('/streaming-support/static/media/fonts/ATTAleckSans_Rg.ttf')
    format('opentype');
}

@font-face {
  font-family: ATTAleckSans_Md;
  src: url('/streaming-support/static/media/fonts/ATTAleckSans_Md.ttf')
    format('opentype');
}

// Font Family
$primary-font: ATTAleckSans_Rg;
$secondary-font: ATTAleckSans_Bd;
$third-font: ATTAleckSans_Md;
